import { observer } from "mobx-react";
import styles from "../../css/UserChallengeFinish.module.scss";
import titleImg from "../../img/title.png";
import rePlay from "../../img/replay.png";
import Stars from "../../components/stars";
import waitImg from "../../img/waiting.png";
import { useNavigate, useParams } from "react-router";
import { Input, message, Spin } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  getBook,
  getBookChallenge,
  getBookChallenges,
  addBookReflection,
  getBookEvaluate,
  startChallenge,
} from "../../utils/request";
import goldImg from "../../img/gold.png";
import silverImg from "../../img/silver.png";
import copperImg from "../../img/copper.png";
import { LayoutContext } from "../auth";
import { useTranslation } from "react-i18next";
function UserChallengeFinish() {

  const [book, setBook] = useState({});
  const { id, challengeId } = useParams();
  const [challenge, setChallenge] = useState();
  const [levelTxt, setLevelTxt] = useState("");
  const [levelImg, setLevelImg] = useState("");
  const [reflection, setReflection] = useState(null);

  const [finishedNum, setFinishedNum] = useState(0);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [starNum, setStarNum] = useState(0);
  const [bookMark, setBookMark] = useState(0);
  const [bookVote, setBookVote] = useState(0);
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { setHead } = useContext(LayoutContext);

  const getChallengeInfo = useCallback(() => {
    getBookChallenge({
      id: challengeId,
      bookId: id,
    }).then((res) => {
      setChallenge(res.data);
      getLevel(res.data.incorrectAnswers);
    });
  }, [challengeId]);

  const getBookInfo = useCallback(() => {
    getBook(id).then((res) => {
      setBook(res.data);
    });
  }, [id]);

  const getEvaluate = useCallback(() => {
    getBookEvaluate(id).then((res) => {
      setBookMark(res.data.mark);
      setBookVote(res.data.vote);
    });
  }, [id]);

  const getChallenges = useCallback(() => {
    getBookChallenges(id).then((res) => {
      setFinishedNum(res.data.length);
      const challenge = res.data.find(item => item.reflection !== null);
      if (challenge) {
        setReflection(challenge.reflection);
      }
    });
  }, [id]);

  useEffect(() => {
    getBookInfo();
    getChallengeInfo();
    getChallenges();
    getEvaluate();
    setHead({
      needMenu: false,
    });
  }, [setHead, getChallengeInfo, getBookInfo, getChallenges, getEvaluate]);

  function getLevel(incorrectCount) {
    if (incorrectCount === 0) {
      setLevelTxt(t('winGoldBadge'));
      setLevelImg(goldImg);
    } else if (incorrectCount <= 2) {
      setLevelTxt(t('winSilverBadge'));
      setLevelImg(silverImg);
    } else if (incorrectCount <= 4) {
      setLevelTxt(t('winCopperBadge'));
      setLevelImg(copperImg);
    }
  }

  function goChallengeAgain() {
    if (finishedNum === 3) return;
    startChallenge({ bookId: id }).then((res) => {
      navigate(`/user/challenge/${id}/${res.data.id}`);
    });
  }

  function goBackToHome() {
    navigate('/user');
  }

  function onsubmit() {
    if (!title) {
      message.warning(t('reflectionTitlePlaceholder'));
      return;
    }

    if (!content) {
      message.warning(t('reflectionContentPlaceholder'));
      return;
    }

    if (starNum === 0) {
      message.warning(t('markRatingLabel'));
      return;
    }

    const data = {
      bookId: id,
      progressId: challengeId,
      title,
      content,
      mark: starNum,
    };

    addBookReflection(data).then((res) => {
      message.success(t('submitSuccess'));
      getChallenges();
    });

  }

  if (!challenge) {
    return <Spin spinning />
  }

  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <div className={styles.contentLeft}>
          <div className={styles.contentTitle}>
            <img src={titleImg} alt="title"></img>
          </div>
          <div className={styles.contentBook}>
            <div className={styles.bookCover}>
              <img src={book.cover} alt="cover"></img>
            </div>
            <div className={styles.bookInfo}>
              <p className={styles.bookName}>{book.title}</p>
              <Stars value={bookMark} count={bookMark} disabled></Stars>
              <p className={styles.bookVote}>{t('vote')}：{bookVote}</p>
            </div>
          </div>
          <div className={styles.contentBless}>
            <p>{t('congratulations')}</p>
            <p>{t('congratulationsContent', { bookTitle: book.title })}</p>
            <p>
              {t('yourScore')}<span>{challenge?.rating}</span>{t('score')} {levelTxt}
            </p>
            <p>
              {t('yourHighestScore')}<span>{challenge?.highestScore}</span>{t('score')}
            </p>
          </div>

          <div className={styles.contentBtnGroup}>
            <div
              className={`${styles.contentBtn} ${finishedNum === 3 ? styles.disabled : ""
                }`}
              onClick={goChallengeAgain}
            >
              <img src={rePlay} alt="rePlay"></img>
              <span>{t('challengeAgain')}（{finishedNum}/3）</span>
            </div>

            <div
              className={styles.contentBtn}
              onClick={goBackToHome}
            >
              <span>{t('backToHome')}</span>
            </div>
          </div>

          <div className={styles.aura}></div>
          {levelImg && (
            <img className={styles.badge} src={levelImg} alt="level"></img>
          )}
          <div className={styles.fire}></div>
        </div>

        {
          !reflection ?
            <div className={styles.contentRight}>
              <p className={styles.rightTitle}>
                {t('writeReflection')}
              </p>

              <div className={styles.rightContent}>
                <div className={[styles.contentBody, styles.contentFirst].join(' ')}>
                  <span className={styles.contentLabel}>标题</span>
                  <Input
                    className={styles.contentInput}
                    onChange={(e) => setTitle(e.target.value)}
                    maxLength={20}
                    placeholder={t('reflectionTitlePlaceholder')}
                  />
                </div>

                <div className={[styles.contentBody, styles.contentSecond].join(' ')}>
                  <span className={styles.contentLabel}>正文</span>
                  <Input.TextArea
                    onChange={(e) => setContent(e.target.value)}
                    maxLength={1000}
                    placeholder={t('reflectionContentPlaceholder')}
                  />
                </div>
                <div className={styles.mark}>
                  <div className={styles.markRating}>
                    <span className={styles.markRatingLabel}>{t('markRatingLabel')}</span>
                    <Stars value={starNum} onChange={(e) => setStarNum(e)}></Stars>
                  </div>
                  <div className={styles.submitBtn} onClick={onsubmit}>
                    {t('submitReflection')}
                  </div>
                </div>
              </div>
            </div> :
            <div className={styles.contentRightWithScore}>
              <p className={styles.contentTitle}>
                {t('reflectionScoreIs')} &nbsp;
                <span>{reflection.rating ? reflection.rating : t('waitingForScore')}</span>
              </p>
              <div className={styles.reflectionInfo}>
                <p className={styles.reflectionTitle}>{reflection.title}</p>
                <p className={styles.reflectionContent}>{reflection.content}</p>
              </div>
              <div className={styles.contentRating}>
                <Stars count={reflection.mark} value={reflection.mark} disabled></Stars>
                <div className={styles.ratingTotal}>
                  <p>{t('totalScore')}：</p>
                </div>
              </div>
              <div className={styles.complete}></div>
              <div className={styles.waiting}>
                {!reflection.rating ? (
                  <img src={waitImg} alt="wait"></img>
                ) : (
                  <span>{reflection.rating + challenge.rating}</span>
                )}
              </div>
            </div>
        }

      </div>
    </div>
  );
}
export default observer(UserChallengeFinish);
